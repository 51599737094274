<template>
  <v-container fluid class="ml-10">
    <br /><br />
    <v-row>
      <v-col cols="12">
        <h1 class="mr-8">
          <v-btn fab dark small color="#479b25" @click="$router.go(-1)">
            <v-icon>mdi-keyboard-return</v-icon>
          </v-btn>
          Detalle del socio
        </h1>
      </v-col>
      <v-col>
        <br />
        <v-container fluid>
          <v-row>
            <v-col cols="12" md="7">
              <v-card max-width="600">
                <v-row>
                  <v-col cols="3" class="ml-5" v-if="partner.photo_url">
                    <v-img
                      style="border-radius: 10px"
                      :src="partner.photo_url"
                      width="100"
                      height="100"
                    />
                  </v-col>
                  <v-col cols="3" class="ml-5" v-else>
                    <v-img
                      style="border-radius: 10px"
                      src="@/assets/images/person.jpg"
                      alt=""
                      width="100"
                      height="100"
                    />
                  </v-col>
                  <v-col cols="7">
                    <v-card-title>
                      <v-row>
                        <v-col class="d-flex justify-start">
                          <h4 style="color: #479b25">
                            {{ partner.fullname }}
                          </h4>
                        </v-col>
                        <v-col class="d-flex justify-end">
                          <a
                            @click="
                              $router.push({
                                name: 'socios-edit',
                                params: { id: partner.id },
                              })
                            "
                          >
                            Editar</a
                          >
                        </v-col>
                      </v-row>
                    </v-card-title>
                    <v-card-subtitle>
                      Socio <br />
                      {{ partner.document_number }}
                    </v-card-subtitle>
                  </v-col>
                </v-row>
                <v-expand-transition>
                  <div>
                    <v-divider />
                    <v-card-text class="pa-10">
                      <v-row>
                        <v-col>
                          <label for="" style="color: #ccc; font-size: 20px"
                            >DNI/ pasaporte:</label
                          >
                          <br />
                          <label for="" style="color: #ccc; font-size: 20px"
                            >Teléfono:</label
                          >
                          <br />
                          <label for="" style="color: #ccc; font-size: 20px"
                            >Email:</label
                          >
                          <br />
                        </v-col>
                        <v-col>
                          <span> {{ partner.document_number }} </span> <br />
                          <span> {{ partner.phone }} </span> <br />
                          <span> {{ partner.email }} </span>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </div>
                </v-expand-transition>
              </v-card>
            </v-col>
            <v-col cols="12" md="5">
              <div v-for="(item, index) in partner.associates" :key="index">
                <v-card max-width="460">
                  <br />
                  <v-row>
                    <v-col cols="3" class="ml-5" v-if="item.photo_url">
                      <v-img
                        style="border-radius: 10px"
                        :src="item.photo_url"
                        width="100px"
                        height="100"
                      />
                    </v-col>
                    <v-col cols="3" class="ml-5" v-else>
                      <v-img
                        style="border-radius: 10px"
                        src="@/assets/images/person.jpg"
                        alt=""
                        width="100px"
                        height="100"
                      />
                    </v-col>
                    <v-col cols="7">
                      <v-card-title>
                        <v-row>
                          <v-col class="d-flex justify-start">
                            <h4 style="color: #479b25">
                              {{ item.fullname }}
                            </h4>
                          </v-col>
                          <v-col class="d-flex justify-end">
                            <a
                              @click="
                                $router.push({
                                  name: 'asociado-edit',
                                  params: { id: item.id },
                                })
                              "
                            >
                              Editar</a
                            >
                          </v-col>
                        </v-row>

                        <!-- <h4 style="color: #479b25">
                          {{ item.fullname }} 
                        </h4> -->
                      </v-card-title>
                      <v-card-subtitle>
                        {{ item.relationship ? item.relationship.name : "" }}
                        <br />
                        {{ item.document_number }}
                      </v-card-subtitle>
                      <v-divider v-if="!item.show" />
                    </v-col>
                    <v-col>
                      <v-btn
                        icon
                        @click="showPartnerAssociated({ id: item.id })"
                      >
                        <v-icon>
                          {{
                            item.show ? "mdi-chevron-up" : "mdi-chevron-down"
                          }}
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-expand-transition>
                    <div v-show="item.show">
                      <v-divider />
                      <v-card-text class="pa-10">
                        <v-row>
                          <v-col>
                            <label for="" style="color: #ccc; font-size: 20px"
                              >DNI/ pasaporte:</label
                            >
                            <br />
                            <label for="" style="color: #ccc; font-size: 20px"
                              >Teléfono:</label
                            >
                            <br />
                            <label for="" style="color: #ccc; font-size: 20px"
                              >Email:</label
                            >
                            <br />
                          </v-col>
                          <v-col>
                            <span>{{ item.document_number }}</span> <br />
                            <span>{{ item.phone }}</span> <br />
                            <span>{{ item.email }}</span>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </div>
                  </v-expand-transition>
                  <br />
                </v-card>
                <br />
              </div>
              <v-row class="ml-12">
                <v-col cols="12">
                  <h2 style="color: #479b25; margin-top: 10px">
                    <v-btn
                      fab
                      color="#479b25"
                      dark
                      @click="
                        $router.push({
                          name: 'asociado-add',
                          params: { membership_id: partner.membership.id },
                        })
                      "
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    Agregar beneficiario
                  </h2>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "partner-details",
  props: ["id"],
  computed: {
    ...mapState({
      partner: (state) => state.partner.partner,
      fetchingData: (state) => state.fetchingData,
    }),
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions(["getPartnerById", "showPartnerAssociated"]),
    getFetch() {
      if (this.id) this.getPartnerById({ id: this.id });
    },
  },
  created() {
    this.getFetch();
  },
};
</script>

<style scoped>
a {
  font-size: 15px;
}
</style>
