var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"ml-10",attrs:{"fluid":""}},[_c('br'),_c('br'),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',{staticClass:"mr-8"},[_c('v-btn',{attrs:{"fab":"","dark":"","small":"","color":"#479b25"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',[_vm._v("mdi-keyboard-return")])],1),_vm._v(" Detalle del socio ")],1)]),_c('v-col',[_c('br'),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('v-card',{attrs:{"max-width":"600"}},[_c('v-row',[(_vm.partner.photo_url)?_c('v-col',{staticClass:"ml-5",attrs:{"cols":"3"}},[_c('v-img',{staticStyle:{"border-radius":"10px"},attrs:{"src":_vm.partner.photo_url,"width":"100","height":"100"}})],1):_c('v-col',{staticClass:"ml-5",attrs:{"cols":"3"}},[_c('v-img',{staticStyle:{"border-radius":"10px"},attrs:{"src":require("@/assets/images/person.jpg"),"alt":"","width":"100","height":"100"}})],1),_c('v-col',{attrs:{"cols":"7"}},[_c('v-card-title',[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-start"},[_c('h4',{staticStyle:{"color":"#479b25"}},[_vm._v(" "+_vm._s(_vm.partner.fullname)+" ")])]),_c('v-col',{staticClass:"d-flex justify-end"},[_c('a',{on:{"click":function($event){return _vm.$router.push({
                              name: 'socios-edit',
                              params: { id: _vm.partner.id },
                            })}}},[_vm._v(" Editar")])])],1)],1),_c('v-card-subtitle',[_vm._v(" Socio "),_c('br'),_vm._v(" "+_vm._s(_vm.partner.document_number)+" ")])],1)],1),_c('v-expand-transition',[_c('div',[_c('v-divider'),_c('v-card-text',{staticClass:"pa-10"},[_c('v-row',[_c('v-col',[_c('label',{staticStyle:{"color":"#ccc","font-size":"20px"},attrs:{"for":""}},[_vm._v("DNI/ pasaporte:")]),_c('br'),_c('label',{staticStyle:{"color":"#ccc","font-size":"20px"},attrs:{"for":""}},[_vm._v("Teléfono:")]),_c('br'),_c('label',{staticStyle:{"color":"#ccc","font-size":"20px"},attrs:{"for":""}},[_vm._v("Email:")]),_c('br')]),_c('v-col',[_c('span',[_vm._v(" "+_vm._s(_vm.partner.document_number)+" ")]),_vm._v(" "),_c('br'),_c('span',[_vm._v(" "+_vm._s(_vm.partner.phone)+" ")]),_vm._v(" "),_c('br'),_c('span',[_vm._v(" "+_vm._s(_vm.partner.email)+" ")])])],1)],1)],1)])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_vm._l((_vm.partner.associates),function(item,index){return _c('div',{key:index},[_c('v-card',{attrs:{"max-width":"460"}},[_c('br'),_c('v-row',[(item.photo_url)?_c('v-col',{staticClass:"ml-5",attrs:{"cols":"3"}},[_c('v-img',{staticStyle:{"border-radius":"10px"},attrs:{"src":item.photo_url,"width":"100px","height":"100"}})],1):_c('v-col',{staticClass:"ml-5",attrs:{"cols":"3"}},[_c('v-img',{staticStyle:{"border-radius":"10px"},attrs:{"src":require("@/assets/images/person.jpg"),"alt":"","width":"100px","height":"100"}})],1),_c('v-col',{attrs:{"cols":"7"}},[_c('v-card-title',[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-start"},[_c('h4',{staticStyle:{"color":"#479b25"}},[_vm._v(" "+_vm._s(item.fullname)+" ")])]),_c('v-col',{staticClass:"d-flex justify-end"},[_c('a',{on:{"click":function($event){return _vm.$router.push({
                                name: 'asociado-edit',
                                params: { id: item.id },
                              })}}},[_vm._v(" Editar")])])],1)],1),_c('v-card-subtitle',[_vm._v(" "+_vm._s(item.relationship ? item.relationship.name : "")+" "),_c('br'),_vm._v(" "+_vm._s(item.document_number)+" ")]),(!item.show)?_c('v-divider'):_vm._e()],1),_c('v-col',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.showPartnerAssociated({ id: item.id })}}},[_c('v-icon',[_vm._v(" "+_vm._s(item.show ? "mdi-chevron-up" : "mdi-chevron-down")+" ")])],1)],1)],1),_c('v-expand-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.show),expression:"item.show"}]},[_c('v-divider'),_c('v-card-text',{staticClass:"pa-10"},[_c('v-row',[_c('v-col',[_c('label',{staticStyle:{"color":"#ccc","font-size":"20px"},attrs:{"for":""}},[_vm._v("DNI/ pasaporte:")]),_c('br'),_c('label',{staticStyle:{"color":"#ccc","font-size":"20px"},attrs:{"for":""}},[_vm._v("Teléfono:")]),_c('br'),_c('label',{staticStyle:{"color":"#ccc","font-size":"20px"},attrs:{"for":""}},[_vm._v("Email:")]),_c('br')]),_c('v-col',[_c('span',[_vm._v(_vm._s(item.document_number))]),_vm._v(" "),_c('br'),_c('span',[_vm._v(_vm._s(item.phone))]),_vm._v(" "),_c('br'),_c('span',[_vm._v(_vm._s(item.email))])])],1)],1)],1)]),_c('br')],1),_c('br')],1)}),_c('v-row',{staticClass:"ml-12"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',{staticStyle:{"color":"#479b25","margin-top":"10px"}},[_c('v-btn',{attrs:{"fab":"","color":"#479b25","dark":""},on:{"click":function($event){return _vm.$router.push({
                        name: 'asociado-add',
                        params: { membership_id: _vm.partner.membership.id },
                      })}}},[_c('v-icon',[_vm._v("mdi-plus")])],1),_vm._v(" Agregar beneficiario ")],1)])],1)],2)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }